@import '~ag-grid/dist/styles/ag-grid.css';
@import '~ag-grid/dist/styles/ag-theme-material.css';
@import url('https://fonts.googleapis.com/css?family=Product+Sans:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

.menu-items {
  line-height: 40px !important;
  min-height: 40px !important;
}

.menu-items svg {
  height: 20px !important;
  width: 20px !important;
  margin: 10px !important;
}

.menu-items > div > div {
  padding: 0 2px 0 40px !important;
}

.modals.dimmer .ui.scrolling.modal {
  margin: 0 !important;
}

.ui.fullscreen.modal {
  width: 100% !important;
  margin: 0 !important;
  height: 95vh !important;
}

.ui.fullscreen.modal .scrolling.content {
  height: calc(85vh);
  max-height: none !important;
  padding: 4px 1rem !important;
}

.ui.dimmer {
  z-index: 1500 !important;
  padding: 0 !important;
}

.ui.modal .scrolling.content {
  height: calc(76vh);
  padding: 4px 1rem !important;
}

.page-modal-buttons {
  display: flex;
  align-items: center;
}

.page-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-modal-search {
  margin: 0 15px;
}

.fullpage-modal {
  background: #fff;

  .ui.dimmer {
    -webkit-user-select: auto !important;
  }

  .ui.page.modals {
    background: #fff;
    padding: 0 !important;
    padding-top: env(safe-area-inset-top) !important;
  }

  .ui.modal {
    width: 100% !important;
    margin: 0 !important;
  }

  .fullpage-modal-content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
  }

  .fullpage-modal-content {
    min-height: auto;
    overflow: auto;
    padding: 1.5rem 1.5rem 2.5rem;
    flex: 1 1 0%;
  }

  .fullpage-modal-footer {
    background: #f9fafb;
    padding: 1rem 1rem env(safe-area-inset-bottom);
    border-top: 1px solid rgba(34, 36, 38, .15);
    text-align: right;
    flex: none;
  }
}

.ui.input > input {
  border: 1px solid rgba(34, 36, 38, 0.47) !important;
}

.page-tools {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    margin: 0 5px !important;
  }
}

.server-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .server-service-btns {
    margin: 5px 0;
  }
}

.notify-box {
  padding: 20px 20px 0px;
  border: 2px solid #38434e33;
  border-radius: 10px;
  background: #f9fafb;
}

// UPLOADER
.uppy-Root {
  z-index: 1500 !important;
}

.uppy-Dashboard-poweredBy {
  display: none !important;
}

.ui.modal .uppy-Dashboard-inner {
  width: 100% !important;
  min-height: 0 !important;
}

.uppy-Dashboard--modal {
  z-index: 100001;
}


.ag-button-cell {
  overflow: visible !important;
  display: flex !important;
  justify-content: left !important;
}

.ag-center-cols-clipper {
  overflow: visible !important;
}

.ag-row .ag-cell {
  display: flex !important;
  align-items: center !important;
}

.ag-row-focus {
  z-index: 1000 !important;
}

.grid-label {
  padding: 4px !important;
  margin: 0 !important;
}

.ag-body-container {
  min-height: 320px !important;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  opacity: .80 !important;
}

.page-filter-container {
  z-index: 10 !important;
}

.page-grid-container {
  z-index: 0 !important;
}

@keyframes callerBtnBackground {
  0% {
    background: #5024ab;
  }
  50% {
    background: #955aff;
  }
  80% {
    background: #6435c9;
  }
  100% {
    background: #5024ab;
  }
}

iframe {
  //display: none !important;
}


.chat-app {
  margin: unset;
  padding: unset;

  display: flex;
  height: 88vh;

  .str-chat__channel-list {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 0;
    flex-shrink: 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

    &--open {
      width: 30%;
      position: fixed;
    }

    transition: width 0.3s ease-out;
  }

  .str-chat__channel {
    flex: 1;
    min-width: 0;
  }

  .str-chat__main-panel {
    min-width: 0;
    flex: 1;

    &--thread-open {
      display: none;
    }
  }

  .str-chat__thread {
    flex: 1;
    height: 100%;
    position: fixed;
    z-index: 1;
  }

  .str-chat__channel-header .str-chat__header-hamburger {
    width: 30px;
    height: 38px;
    padding: var(--xxs-p);
    margin-right: var(--xs-m);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: transparent;

    &:hover {
      svg path {
        fill: var(--primary-color);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .str-chat__channel-list {
      width: 30%;
      position: initial;
      z-index: 0;
    }

    .str-chat__thread {
      position: initial;
      z-index: 0;
    }

    .str-chat__channel-header .str-chat__header-hamburger {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    .str-chat__main-panel {
      min-width: 0;

      &--thread-open {
        max-width: 55%;
        display: flex;
      }
    }

    .str-chat__thread {
      max-width: 45%;
    }

    .str-chat__channel-header .str-chat__header-hamburger {
      display: none;
    }
  }
}

