* {
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
}

body {
    background: #EEF1F5;
}

p, strong, h1, h2, h3, h4, h5, th {
    cursor: default;
}

.frameless {
    -ms-overflow-style: scrollbar;
    -webkit-app-region: drag;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="text"],
button,
a,
textarea {
    -webkit-app-region: no-drag;
}

.ag-theme-material {
    font: 400 12px "Roboto", sans-serif !important;
}

.ag-theme-material .ag-cell {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 3px !important;
    line-height: 20px !important;
    white-space: pre-wrap !important;
}

.ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell {
    padding-left: 5px !important;;
    padding-right: 5px !important;;
}

.swal2-container.swal2-shown {
    background-color: rgba(0, 0, 0, 0.85) !important;
}

.blurring.dimmable > .dimmer {
    background-color: rgba(0, 0, 0, 0.85) !important;
}

.ui.form.huge .field > label {
    color: #ffffff !important;
}

.swal2-container {
    z-index: 2060 !important;
}